import { CustomLink } from '@/components';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { Button, InputWithLabel, PasswordInput } from '@/ui/components';
import styled from 'styled-components';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledInputWithLabel = styled(InputWithLabel)`
  margin-bottom: 24px;
  width: 100%;
`;

export const StyledPasswordInput = styled(PasswordInput)`
  margin-bottom: 24px;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  margin-bottom: 16px;
  max-width: none;
  @media (max-width: 600px) {
    margin-bottom: 16px;
    margin-right: 0;
    max-width: none;
  }
`;

export const ResetPasswordLink = styled(CustomLink)`
  ${TYPOGRAPHY.body2Medium16};
  color: ${COLORS.black};
  margin-bottom: 24px;
`;

export const DontHaveDataLink = styled(CustomLink)`
  ${TYPOGRAPHY.body2Medium16};
  color: ${COLORS.grayDarker};
  text-align: center;
  align-self: center;
`;
