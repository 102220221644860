import React, { FC, useState } from 'react';
import { TabData } from '@/types';
import { COLORS, TYPOGRAPHY } from '@/constants';
import styled from 'styled-components';
import { Container, TitleWithBackButton } from '@/components';
import { Tabs } from '@/ui/components';
import {
  LoginByEmailForm,
  LoginByPhoneForm,
} from '@/pageComponents/SignInPage/SignIn/components';

enum LoginTabs {
  phone = 'phone',
  email = 'email',
}

const tabsData: TabData[] = [
  {
    text: 'Мобильный телефон',
    value: LoginTabs.phone,
  },
  {
    text: 'E-mail',
    value: LoginTabs.email,
  },
];

const SignIn: FC = () => {
  const [activeTab, setActiveTab] = useState<LoginTabs>(LoginTabs.phone);

  return (
    <Root>
      <StyledContainer>
        <Wrapper>
          <StyledTitleWithBackButton text="Вход в личный кабинет" />
          <TabsWrapper>
            <StyledTabs
              data={tabsData}
              onToggle={setActiveTab}
              activeValue={activeTab}
            />
          </TabsWrapper>

          <TabContent $isActive={activeTab === LoginTabs.phone}>
            <LoginByPhoneForm />
          </TabContent>
          <TabContent $isActive={activeTab === LoginTabs.email}>
            <LoginByEmailForm />
          </TabContent>
        </Wrapper>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.grayLighter};
`;

const StyledContainer = styled(Container)`
  padding-top: 48px;
  padding-bottom: 80px;
  @media (max-width: 1024px) {
    padding-bottom: 64px;
  }
`;

const Wrapper = styled.div`
  background-color: ${COLORS.white};
  border-radius: 18px;
  padding: 48px;
  max-width: 480px;
  margin: 0 auto;
  @media (max-width: 1024px) {
    padding: 0;
    background-color: transparent;
  }
`;

const StyledTitleWithBackButton = styled(TitleWithBackButton)`
  margin-bottom: 32px;
  @media (max-width: 600px) {
    ${TYPOGRAPHY.headline1Medium24};
  }
`;

const TabsWrapper = styled.div`
  overflow: auto;
  margin-bottom: 36px;
  @media (max-width: 768px) {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 10px;
    margin-left: -16px;
    margin-right: -16px;
  }
`;

const StyledTabs = styled(Tabs)`
  border: 1px solid ${COLORS.gray1};
  display: flex;
  button {
    display: flex;
    flex: 1;
    padding-left: 5px;
    padding-right: 5px;
  }
`;

const TabContent = styled.div<{ $isActive: boolean }>`
  display: ${({ $isActive }) => ($isActive ? '' : 'none')};
`;

export default SignIn;
