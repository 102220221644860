import { signInByPhoneThunk } from '@/store/ducks/auth/thunks';
import { useAppDispatch } from '@/store/hooks';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { navigate } from 'gatsby';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { LINKS, REGEXPS } from '@/constants';
import { ErrorTexts } from '@/types';

import {
  DontHaveDataLink,
  ResetPasswordLink,
  StyledButton,
  StyledForm,
  StyledInputWithLabel,
  StyledPasswordInput,
} from '../styles';

interface FormData {
  number: 'string';
  password: 'string';
}

const schema = yup
  .object({
    number: yup
      .string()
      .matches(REGEXPS.phoneRegExp, ErrorTexts.incerrectPhoneNumber)
      .required(ErrorTexts.required),

    password: yup
      .string()
      .min(6, ErrorTexts.lengthPassword)
      .required(ErrorTexts.required),
  })
  .required();

const LoginByPhoneForm: FC = () => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors, isSubmitting, isValid, isDirty },
    getFieldState,
  } = useForm<FormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const isSuccessField = (field: keyof FormData) =>
    getFieldState(field, formState).isDirty && !errors[field];

  const onSubmit = handleSubmit(async (data) => {
    try {
      const result = await dispatch(signInByPhoneThunk(data)).unwrap();
      if (result) {
        navigate('/profile');
      }
    } catch (rejectedValueOrSerializedError) {}
  });

  return (
    <StyledForm onSubmit={onSubmit}>
      <StyledInputWithLabel
        label="Мобильный телефон"
        type="tel"
        placeholder="+7 (903) 485 24-32"
        mask="+7 (999) 999-99-99"
        errorMessage={errors.number?.message}
        isSuccess={isSuccessField('number')}
        {...register('number')}
      />
      <StyledPasswordInput
        label="Пароль"
        placeholder="Пароль"
        errorMessage={errors.password?.message}
        isSuccess={isSuccessField('password')}
        {...register('password')}
      />
      <ResetPasswordLink to={LINKS.resetPassword}>
        Восстановить пароль
      </ResetPasswordLink>

      <StyledButton
        type="submit"
        disabled={!isDirty || !isValid}
        isLoading={isSubmitting}
      >
        Вход
      </StyledButton>
      <DontHaveDataLink to={LINKS.signUp}>
        Зарегистрироваться
      </DontHaveDataLink>
    </StyledForm>
  );
};

export default LoginByPhoneForm;
