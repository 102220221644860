import React from 'react';
import { MainLayout } from '@/layouts';
import { MainHeading, Seo } from '@/components';
import { SeoData } from '@/types';
import retireds from '@/assets/images/retireds.png';
import { COLORS, LINKS } from '@/constants';
import { SignIn } from '@/pageComponents/SignInPage';
import { selectors } from '@/store/ducks';
import { navigate } from 'gatsby';
import { useAppSelector } from '@/store/hooks';

export default function SignInPage() {
  const seoData: SeoData = {
    metaTitle: 'Социальный проект «Выплаты»',
    metaDescription: 'Социальный проект «Выплаты»',
    keywords: 'пенсия, деньги, выплата',
    sharedImage: {
      url: retireds,
    },
  };

  const token = useAppSelector(selectors.auth.selectUserToken);
  if (token) {
    navigate(LINKS.profile);
    return null;
  }

  return (
    <MainLayout backgroundColor={COLORS.grayLighter} fullHeight={true}>
      <Seo data={seoData} />
      <MainHeading text={seoData.metaTitle} />
      <SignIn />
    </MainLayout>
  );
}
